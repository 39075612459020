import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { errorNotification } from 'src/actions/notification';
import { RESET_STORE } from 'src/constants/actions';
import { login } from 'src/constants/routes';
import { postLogoutUserService } from 'src/services/user/postLogoutUserService';
import { resetAuthStore } from 'src/store/authStore/authStore';
import { clearAllCookies } from 'src/utils/cookies';
import { APIError, getErrorMessage } from 'src/utils/error';
import { AnalyticsEvents, googleAnalytics } from 'src/utils/googleAnalytics';

type PostLogoutUserMutationProps = {
  options?: UseMutationOptions<void, APIError, void>;
};

export function useDeleteUserSession(
  props: PostLogoutUserMutationProps = {}
): UseMutationResult<void, APIError, void> {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { onSuccess, onError, ...otherOptions } = props?.options || {};

  return useMutation({
    mutationFn: async () => postLogoutUserService(),
    onSuccess: (...context) => {
      resetAuthStore();
      queryClient.removeQueries();
      history.push(login);
      clearAllCookies();

      // dispatch RESET_STORE action to reset the redux store
      dispatch({ type: RESET_STORE });

      googleAnalytics.track(AnalyticsEvents.LOGOUT);
      googleAnalytics.identify('');

      onSuccess?.(...context);
    },
    onError: (err, variables, context) => {
      dispatch(errorNotification(getErrorMessage(err)));
      onError?.(err, variables, context);
    },
    ...otherOptions,
  });
}
