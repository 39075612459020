import { authApi } from 'src/constants/api';
import { blankRedirect } from 'src/constants/routes';

export function routeToURI(route: string): string {
  return `${location.protocol}//${location.host}${route}`;
}

/**
 * Calling the `provider/redirect` endpoint results in a user facing redirect (302)
 * and must be called in a synchronous (non-XHR) manner.
 * To achieve this, this function creates a form object programmatically
 * with the
 * - provider: microsoft
 * - process: login
 * - callback_url: /blank-redirect
 *  hidden values and submits the form to start the Microsoft login process.
 *
 * @see https://docs.allauth.org/en/latest/headless/openapi-specification/#tag/Authentication:-Providers/paths/~1_allauth~1browser~1v1~1auth~1provider~1redirect/post
 *
 */
export function redirectToMicrosoftLogin(): void {
  const requestObject = {
    provider: 'microsoft',
    process: 'login',
    callback_url: routeToURI(blankRedirect),
  };

  const form = document.createElement('form');
  form.method = 'POST';
  form.action = authApi.providerRedirect();

  // eslint-disable-next-line no-restricted-syntax
  for (const key in requestObject) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = requestObject[key];

    form.appendChild(input);
  }

  document.body.appendChild(form);

  form.submit();
}
