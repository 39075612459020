import React from 'react';
import { SubscriptionProductFullName } from '../typings/Subscription';
import { changeAirtameResolutionUrl, troubleshootingSoundsUrl } from './url';

export const emailAddress = 'Email';
export const displayName = 'Your name';
export const newPassword = 'New password';
export const organizationName = 'Organization name';
export const password = 'Password';
export const copy = 'Copy token';
export const createGroup = 'Create';
export const cancel = 'Cancel';
export const close = 'Close';

// Edit Device Settings Form
export const deviceName = 'Device name';
export const custom = 'Guide';
export const minimal = 'Minimal';
export const resolution = 'Resolution';
export const background = 'Background';
export const updateChannel = 'Update channel';
export const standbyHoursAutomatic = 'When not in use';
export const ap = 'Access point';
export const ap24 = '2.4 GHz Access point';
export const ap52 = '5.2 GHz Access point';
export const apPsk = 'AP password';
export const deviceCustomSplashTextTitle = 'Overlay text';
export const deviceGuestStreamingCustomTextTitle = 'Browser sharing instructions';
export const deviceCustomTextPreview = 'Preview text';
export const ga = 'General releases (recommended)';
export const alpha = 'Alpha';
export const nightly = 'Nightly';
export const beta = 'Beta';
export const defaultSplashText = `# Present Wirelessly

Connect to WiFi **{{WIFI_NAME}}**

Download app **airtame.com/start**

Present from app **{{DEVICE_NAME}}** or type **{{DEVICE_IP}}**
`;

export const defaultGuestStreamingCustomText = (website: string): string => `### Share without app

Go to **${website}** and type:
{{PAIRING_CODE}}
`;
export const guestStreamingUrl = 'airtame.com/share';
export const splashBgColor = 'Background color';
export const splashTextColor = 'Text color';
export const homescreenOrientation = 'Orientation';
export const reset = 'Reset';
export const RESOLUTION_NOT_SUPPORTED = `The current resolution is not supported. \
Select a different resolution from the drop-down.`;

export const EthernetLabel = 'Ethernet';
export const WIFILabel = 'Wi-Fi';

export const editResolutionFailedHtml = ({ className } = { className: '' }): React.ReactElement => {
  return (
    <span className={className}>
      Airtame failed to read supported resolutions from the connected display. Learn more about how
      Airtame handles resolutions in our{' '}
      <a target="_blank" href={changeAirtameResolutionUrl}>
        help article
      </a>
      .
    </span>
  );
};

export const audioNotSupportedOnResolutionHtml = (
  { className } = { className: '' }
): React.ReactElement => {
  return (
    <span className={className}>
      The selected resolution does not support audio,{' '}
      <a target="_blank" href={troubleshootingSoundsUrl}>
        learn more
      </a>
      .
    </span>
  );
};

export const timezone = 'Timezone';
export const preferredClockFormat = 'Preferred clock format';

export const NOT_FOUND = 'Not found';
export const NOT_CONNECTED = 'Not connected';
export const NOT_CONFIGURED = 'Not configured';
export const upgradeSubscriptionButtonLabel = 'Change subscription';
export const cancelSubscriptionButtonLabel = 'Cancel subscription';

export const assignLicenseButtonLabel = 'Save';

// eslint-disable-next-line max-len
export const screensEmptyGroupLabel = `This group contains no devices from your ${SubscriptionProductFullName.plus} plan.`;

export const GOOGLE = 'google';
export const MICROSOFT = 'microsoft';
export const GOOGLE_CALENDAR_LABEL = 'Google Calendar';
export const MICROSOFT_CALENDAR_LABEL = 'Office 365';
export const CALENDAR_NONE = 'none';
export const CALENDAR_NONE_LABEL = 'None';

export const homescreenAppDefaultImageLabel = 'default';

// Checkout page
export const checkoutPlansSectionHeading = 'Subscription';
export const checkoutPaymentSummarySectionHeading = 'Payment summary';
export const checkoutPaymentSummaryUpdateSectionHeading = 'Summary';
export const summaryNoChanges = 'You have made no changes to your subscription';
export const summaryTrialTotal = (chargingDate: string | null): string =>
  `Amount to be charged on ${chargingDate}`;
export const summaryTotal = 'Amount charged now';
export const summaryButtonLabel = 'Subscribe';
export const summaryUpgradeButtonLabel = 'Confirm changes';
export const subscriptionSummaryNextInvoice = (price: string): React.ReactElement => (
  <span className="summary-renewal-next">Your next invoice will be {price}.</span>
);

export const subscriptionSummaryRenewal = (
  frequency: string,
  renewalPrice: string
): React.ReactElement => (
  <span className="summary-renewal-next">
    Your new {frequency} total will be {renewalPrice}.
  </span>
);

export const defaultVideoLoadError = 'The selected file format cannot be previewed';
export const supportedVideoTypesContent = (): React.ReactElement => (
  <>
    <a href="https://www.chromium.org/audio-video" target="_blank">
      Supported video formats:
    </a>
    <ul>
      <li>MP4 (QuickTime / MOV / MPEG4)</li>
      <li>Ogg</li>
    </ul>
  </>
);

export const videoMuteCopy = `Stop sound output for the added video or playlist`;
