import { userApi } from 'src/constants/api';
import * as http from 'src/constants/http';
import { fetchJson } from 'src/utils/fetch';

// NOTE: This endpoint logout the user and remove the cookies:
// - sessionId
// - access_token
// This endpoint works with the legacy auth system and the new allauth system in the backend
export function postLogoutUserService(): Promise<void> {
  return fetchJson(userApi.userLogoutLegacy(), {
    method: http.POST,
  });
}
