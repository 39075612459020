import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import semver, { SemVer } from 'semver';
import * as formLabels from 'src/constants/formLabels';
import {
  ConnectionMode,
  ConnectionStatus,
  DeviceNetworkData,
  DeviceNetworkInterface,
  DevicePlatform,
  DeviceState,
  NetworkFrequencyBand,
} from 'src/typings/Device';
import { DeviceOverview, DeviceOverviewById, DeviceStatus } from 'src/typings/DeviceOverview';
import { DeviceLicense } from 'src/typings/DeviceSettings';
import {
  SubscriptionLicense,
  SubscriptionProductLicense,
  SubscriptionProductName,
} from 'src/typings/Subscription';
import { hardwareDevices } from './constant';

export function getSubscriptionLicense(
  deviceLicense: DeviceLicense
): SubscriptionLicense | undefined {
  return (Object.keys(deviceLicense) as SubscriptionLicense[]).reduce((license, licenseKey) => {
    if (deviceLicense[licenseKey]) {
      return licenseKey;
    }

    return license;
  }, undefined);
}

/**
 * Get the active product taking into consideration
 * the subscriptionProducts and the trialProducts.
 *
 * Note that the trialProducts are ignored if we have already assigned a subscription
 * license to the current device.
 */
export function getProductsFromSubscriptionAndTrial(
  subscription: SubscriptionProductName[],
  trials: SubscriptionProductName[]
): SubscriptionProductName[] {
  if (subscription.length) {
    return subscription;
  }

  return trials;
}

/**
 * When we enable trials we can get into a complex state where we have
 * available core and hybrid at the same time.
 *
 * To avoid confusion, we remove hybrid products for DG1 and DG2 platform and
 * we remove core products if hybrid is available for CD1 and VA.
 */
export function getLicenseProductsByPlatformType(
  platform: DevicePlatform,
  products: SubscriptionProductName[]
): SubscriptionProductName[] {
  if (isDeviceDG1orDG2(platform)) {
    return products.filter(product => product !== SubscriptionProductName.rooms);
  }

  if (canDevicePlatformHaveHybridLicense(platform)) {
    if (products.includes(SubscriptionProductName.rooms)) {
      return products.filter(product => product === SubscriptionProductName.rooms);
    }

    return products;
  }

  return products;
}

export function getActiveSubscriptionProduct(
  products: SubscriptionProductName[]
): SubscriptionProductName | undefined {
  return Object.keys(SubscriptionProductName).reduce<SubscriptionProductName | undefined>(
    (acc, key) => {
      if (products.includes(SubscriptionProductName[key])) {
        return SubscriptionProductName[key] as SubscriptionProductName;
      }

      return acc;
    },
    undefined
  );
}

export function getDeviceProductLicenseShortName(
  productName?: SubscriptionProductName
): SubscriptionProductLicense {
  if (!productName) return SubscriptionProductLicense.unlicensed;

  return SubscriptionProductLicense[productName];
}

/**
 * Fallback to DG1 if the platform is either undefined or unsupported.
 */
export function getDevicePlatform(platform?: DevicePlatform): DevicePlatform {
  return platform ? DevicePlatform[platform] : DevicePlatform.DG1;
}

export function isDeviceDG1orDG2(platform: DevicePlatform): boolean {
  return isDG1(platform) || isDG2(platform);
}

export function canDevicePlatformHaveCoreLicense(platform: DevicePlatform): boolean {
  return isDeviceDG1orDG2(platform) || canDevicePlatformHaveHybridLicense(platform);
}

export function canDevicePlatformHaveHybridLicense(platform: DevicePlatform): boolean {
  return isCD1(platform) || isDG3(platform) || isVirtualAirtame(platform);
}

export function canDevicePlatformSupportScreenshot(platform: DevicePlatform): boolean {
  return !isVaAndroid(platform);
}

/**
 * Checks if the device is online
 */
export function isDeviceOnline(lastSeen: number, isOnline: boolean): boolean {
  return isOnline || lastSeen < 20;
}

/**
 * isStreaming encapsulates the condition that the device is 'streaming'
 */
export function isStreaming(status: DeviceStatus): boolean {
  return status === DeviceStatus.streaming;
}

/**
 * isStandby encapsulates the condition that the device is on standby
 */
export function isStandby(status: DeviceStatus): boolean {
  return status === DeviceStatus.sleep;
}

/**
 * isUpdating encapsulates the condition that the device is 'updating'
 */
export function isUpdating(status: DeviceStatus): boolean {
  return status === DeviceStatus.updating;
}

/**
 * isRebooting encapsulates the condition that the device is 'rebooting'
 */
export function isRebooting(status: DeviceStatus): boolean {
  return status === DeviceStatus.rebooting;
}

/**
 * isDG1 encapsulates the condition that the device's platform is DG1
 */
export function isDG1(platform: DevicePlatform): boolean {
  return platform === DevicePlatform.DG1;
}

/**
 * isDG2 encapsulates the condition that the device's platform is DG2
 */
export function isDG2(platform: DevicePlatform): boolean {
  return platform === DevicePlatform.DG2;
}

/**
 * isDG3 encapsulates the condition that the device's platform is DG3
 */
export function isDG3(platform: DevicePlatform): boolean {
  return platform === DevicePlatform.DG3;
}

/**
 * isCD1 encapsulates the condition that the device's platform is CD1
 */
export function isCD1(platform: DevicePlatform): boolean {
  return platform === DevicePlatform.CD1;
}

export function isVaWindows(platform: DevicePlatform): boolean {
  return platform === DevicePlatform.VA_WIN;
}

export function isVaAndroid(platform: DevicePlatform): boolean {
  return platform === DevicePlatform.VA_ANDROID || platform === DevicePlatform.VA_ANDROID_SMART;
}

export function isVirtualAirtame(platform: DevicePlatform): boolean {
  const virtualPlatforms: DevicePlatform[] = [
    DevicePlatform.VA_ANDROID,
    DevicePlatform.VA_ANDROID_SMART,
    DevicePlatform.VA_WIN,
  ];

  return virtualPlatforms.includes(platform);
}

export function getDeviceStatus(state: DeviceState, isOnline: boolean): DeviceStatus {
  if (!isOnline) {
    return DeviceStatus.offline;
  }

  switch (state) {
    case DeviceState.idle:
      return DeviceStatus.online;
    case DeviceState.streaming:
      return DeviceStatus.streaming;
    case DeviceState.updating:
      return DeviceStatus.updating;
    case DeviceState.rebooting:
      return DeviceStatus.rebooting;
    case DeviceState.resetting:
      return DeviceStatus.resetting;
    case DeviceState.standby:
      return DeviceStatus.sleep;
    default:
      return DeviceStatus.online;
  }
}

/**
 * Utility to filter organization devices to return all the pro devices.
 */
export function getPlusDevices(devicesById: DeviceOverviewById): DeviceOverview[] {
  return Object.values(devicesById).filter(d =>
    getDeviceProductsList(d).includes(SubscriptionProductName.plus)
  );
}

export function getEducationDevices(devicesById: DeviceOverviewById): DeviceOverview[] {
  return Object.values(devicesById).filter(d =>
    getDeviceProductsList(d).includes(SubscriptionProductName.edu)
  );
}

export function getRoomsDevices(devicesById: DeviceOverviewById): DeviceOverview[] {
  return Object.values(devicesById).filter(d =>
    getDeviceProductsList(d).includes(SubscriptionProductName.rooms)
  );
}

function getDeviceProductsList(deviceOverview: DeviceOverview): SubscriptionProductName[] {
  return [...deviceOverview.subscriptionProducts];
}

/**
 * Consider any non-valid semver version as custom, this should provide flexibility for any
 * future version build by the FW team
 * @param {SemVer} version firmware version
 * @returns {boolean} true is the version is custom, false otherwise
 */
export function isCustomVersion(version: string | SemVer): boolean {
  return semver.valid(version) === null;
}

export function getNetworkData(
  networkState: Record<string, DeviceNetworkInterface>,
  isDeviceOnline: boolean
): DeviceNetworkData {
  // Device is connected either via ethernet or wifi
  // Ethernet interface is preferred
  const ei = networkState.ethernet;

  // Ethernet should be either connected or
  // it's status can be timeout if the device itself is online
  // such state can only come up with RADIUS Ethernet authentication
  const isEthernetOnline =
    isEthernetConnected(networkState) || (isEthernetTimedOut(networkState) && isDeviceOnline);

  if (ei && isEthernetOnline) {
    return {
      ip: ei.ip,
      network: formLabels.EthernetLabel,
      rssi: 0,
      mac: ei.mac,
    };
  }

  const apWifiConnected = findConnectedWifiNetwork(networkState);
  if (apWifiConnected) {
    return {
      ip: apWifiConnected.ip,
      network: apWifiConnected.ssid,
      rssi: apWifiConnected.signal_strength,
      mac: apWifiConnected.mac,
      band: getNetworkBand(apWifiConnected),
    };
  }

  // NOTE: The device networkState reports a disconnection on the WiFi interface, while the device
  // is still "online". This is a work-around to show the IP and Network name even though the
  // WiFi network interface status is disconnected.
  // In the future this step will not be necessary once the parsing on the data in the backend
  // correlates with what the real status of the device networking.
  const apWifiDisconnected = findWifiClientInterface(networkState);
  if (isDeviceOnline && apWifiDisconnected) {
    return {
      ip: apWifiDisconnected.ip,
      network: apWifiDisconnected.ssid,
      rssi: 'unknown',
      mac: apWifiDisconnected.mac,
      band: getNetworkBand(apWifiDisconnected),
    };
  }

  return { ip: 'unknown', network: 'unknown', rssi: 'unknown', mac: 'unknown' };
}

/**
 * Takes the raw interfaces response from the device and computes a map
 * <interfaceName, interfaceObject>. E.g. <'ethernet', {ip: ..., mac: ...}>
 * @param networkInterfaceList device interfaces as returned by /admin/device/network-state
 * @returns JS Map object
 */
export function getNetworkStateMap(
  networkInterfaceList: DeviceNetworkInterface[] | null
): Record<string, DeviceNetworkInterface> {
  if (!networkInterfaceList?.length) {
    return {};
  }

  const networkInterfaces: Record<string, DeviceNetworkInterface> = {};

  // group interfaces by type
  const interfacesGroup = groupBy(networkInterfaceList, networkInterface => networkInterface.type);
  // the wifi interfaces will be added as 'ap24' and 'ap52' keys
  Object.entries(interfacesGroup).forEach(([type, network]) => {
    if (type === 'wifi') {
      // In case of multiple interfaces for frequencies,
      // We should choose the one with mode = client and an ip, whenever possible
      orderBy(network, ni => ni.mode === 'client' && !!ni.ip, 'asc').forEach(ni => {
        // add bridge IP if wifi interface is in ap mode
        if (!ni.mode || ni.mode === 'ap') {
          ni.ip = interfacesGroup.bridge[0].ip;
        }

        if (is24G(ni)) {
          networkInterfaces.ap24 = { ...ni, band: NetworkFrequencyBand.FREQUENCY_2GHZ };
        }

        if (is5G(ni)) {
          networkInterfaces.ap52 = { ...ni, band: NetworkFrequencyBand.FREQUENCY_5GHZ };
        }
      });
    } else {
      networkInterfaces[type] = network[0];
    }
  });
  return networkInterfaces;
}

export function findConnectedWifiNetwork(
  networkInterfaces: Record<string, DeviceNetworkInterface> | null
): DeviceNetworkInterface | null {
  const wifiInterface = findWifiClientInterface(networkInterfaces);

  return wifiInterface?.status === ConnectionStatus.CONNECTED ? wifiInterface : null;
}

export function findWifiClientInterface(
  networkInterfaces: Record<string, DeviceNetworkInterface> | null
): DeviceNetworkInterface | null {
  if (!networkInterfaces) {
    return null;
  }

  const keys = ['ap24', 'ap52'];
  let wifiNetwork: DeviceNetworkInterface | null = null;

  keys.forEach(k => {
    const wifiInterface = networkInterfaces[k];
    if (wifiInterface?.mode === ConnectionMode.CLIENT) {
      wifiNetwork = { ...wifiInterface, band: getNetworkBand(wifiInterface) };
    }
  });

  return wifiNetwork;
}

/**
 * @param network wifi network object
 * @returns string representation of network band e.g '2.4GHz'
 */
export function getNetworkBand(networkInterface: DeviceNetworkInterface): NetworkFrequencyBand {
  if (is5G(networkInterface)) {
    return NetworkFrequencyBand.FREQUENCY_5GHZ;
  } else {
    return NetworkFrequencyBand.FREQUENCY_2GHZ;
  }
}

export function is5G(networkInterface: DeviceNetworkInterface | null): boolean {
  return networkInterface?.frequency ? networkInterface.frequency >= 5000 : false;
}

export function is24G(networkInterface: DeviceNetworkInterface | null): boolean {
  return networkInterface?.frequency
    ? networkInterface.frequency > 2300 && networkInterface.frequency < 5000
    : false;
}

/**
 * Verifies if a device is connected to ethernet
 * @param {Object}    networkStateMap The network State Map
 * @return {boolean}               Whether the device is connected via ethernet or not
 */
export function isEthernetConnected(
  networkStateMap: Record<string, DeviceNetworkInterface>
): boolean {
  return networkStateMap.ethernet?.status === ConnectionStatus.CONNECTED;
}

export function isEthernetTimedOut(
  networkStateMap: Record<string, DeviceNetworkInterface>
): boolean {
  return networkStateMap.ethernet?.status === ConnectionStatus.TIMEOUT;
}

export function isHardwareDevice(platform: DevicePlatform): boolean {
  return hardwareDevices.includes(platform);
}
