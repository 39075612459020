/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RootState } from 'src/reducers';
import {
  SubscriptionCurrentStatus,
  SubscriptionPaymentType,
  SubscriptionStatusType,
} from 'src/typings/Subscription';

// subscription
export const selectHasExistingSubscription = (state: RootState) =>
  !!state.subscription.subscriptionInfo;
export const selectPlusProduct = (state: RootState) => state.subscription.plusProduct;
export const selectEducationProduct = (state: RootState) => state.subscription.educationProduct;
export const selectRoomsProduct = (state: RootState) => state.subscription.roomsProduct;
export const selectSubscriptionInfo = ({ subscription }: RootState) =>
  subscription.subscriptionInfo;
export const selectIsSubscriptionInitialized = (state: RootState) =>
  state.subscription.isInitialized;
export const selectIsSubscriptionFetched = (state: RootState) =>
  state.subscription.isSubscriptionFetched;
export const selectIsActiveSubscription = ({ subscription }: RootState) =>
  subscription.subscriptionInfo?.status === SubscriptionCurrentStatus.Active ||
  subscription.subscriptionInfo?.status === SubscriptionCurrentStatus.PastDue;
export const selectIsActiveTrial = ({ subscription }: RootState) =>
  subscription.plusProduct.statusType === SubscriptionStatusType.TRIAL ||
  subscription.educationProduct.statusType === SubscriptionStatusType.TRIAL ||
  subscription.roomsProduct.statusType === SubscriptionStatusType.TRIAL;

export const selectIsCanceledSubscription = ({ subscription }: RootState) =>
  subscription.subscriptionInfo?.status === SubscriptionCurrentStatus.Canceled;
export const selectIsContractPaymentMethod = ({ subscription }: RootState) =>
  subscription.subscriptionInfo?.paymentType === SubscriptionPaymentType.Contract;
export const selectIsCreditCardPaymentMethod = ({ subscription }: RootState) =>
  subscription.subscriptionInfo?.paymentType === SubscriptionPaymentType.CreditCard;
export const selectIsFetchingSubscriptionStatus = ({ subscription }: RootState) =>
  subscription.isFetchingStatus;

// notification
export const selectNotifications = (state: RootState) => state.notifications;

// serviceIntegration
export const selectIntegrationIsAuthenticating = (state: RootState) =>
  state.serviceIntegration.isAuthenticating;

export const selectIntegrationMicrosoftEmail = (state: RootState) =>
  state.serviceIntegration.microsoftEmail;

export const selectIntegrationMicrosoftId = (state: RootState): number | null =>
  state.serviceIntegration.microsoftId;

export const selectIntegrationMicrosoftAccessToken = (state: RootState) =>
  state.serviceIntegration.microsoftAccessToken;
