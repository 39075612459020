import { userApi } from 'src/constants/api';
import { User } from 'src/typings/User';
import { fetchJson } from 'src/utils/fetch';
import { UserApiModel } from './apiModels';

type GetUserApiResponse = UserApiModel;

type getUserServiceOptions = {
  logoutOn401: boolean;
};

export async function getUserService(options?: getUserServiceOptions): Promise<User> {
  const response: GetUserApiResponse = await fetchJson(
    userApi.user(),
    undefined,
    options?.logoutOn401 ?? true
  );

  return response;
}
