import { RESET_STORE } from 'src/constants/actions';
import { login } from 'src/constants/routes';
import { queryClient } from 'src/queries/queryClient';
import { postLogoutUserService } from 'src/services/user/postLogoutUserService';
import { resetAuthStore } from 'src/store/authStore';
import { store } from 'src/store/store';
import { clearAllCookies } from '../cookies';
import { AnalyticsEvents, googleAnalytics } from '../googleAnalytics';
import { rootHistory } from '../history';

/**
 * Utility function to logout the user outside of React.
 *
 */
export function logoutUser(): void {
  queryClient
    .getMutationCache()
    .build(queryClient, {
      mutationFn: () => postLogoutUserService(),
      onSuccess: () => {
        resetAuthStore();
        queryClient.removeQueries();
        rootHistory.push(login);
        store.dispatch({ type: RESET_STORE });
        clearAllCookies();

        googleAnalytics.track(AnalyticsEvents.LOGOUT);
        googleAnalytics.identify('');
      },
    })
    .execute({});
}
