import { OauthServiceEnum } from 'src/hooks/useOauth';
import { AuthGoogleServices } from 'src/typings/Auth';
import { DeviceCommand, DevicePlatform } from 'src/typings/Device';
import { BACKEND_URL } from '../constants/parameters';
import { backendUrl } from './url';

const apiVersions = {
  v2: 'v2.0',
  v3: 'v3.0',
} as const;

const apiBaseUrl = BACKEND_URL;
const apiUrlV2 = `${apiBaseUrl}/${apiVersions.v2}`;
const apiUrlV3 = `${apiBaseUrl}/${apiVersions.v3}/cloud`;
const authApiBaseUrl = `${apiBaseUrl}/${apiVersions.v3}/auth/browser/v1/auth`;

export const authApi = {
  googleService: (googleService: AuthGoogleServices): string =>
    `${apiBaseUrl}/app-backends/${googleService}/authenticate`,
  googleTokenByEmail: (googleService: AuthGoogleServices, googleEmail: string): string =>
    `${apiBaseUrl}/app-backends/${googleService}/${googleEmail}/user-access-token`,
  login: (): string => `${authApiBaseUrl}/login`,
  providerLogin: (): string => `${authApiBaseUrl}/provider/token`,
  providerRedirect: (): string => `${authApiBaseUrl}/provider/redirect`,
  session: (): string => `${authApiBaseUrl}/session`,
  requestPasswordReset: (): string => `${authApiBaseUrl}/password/request`,
  validatePasswordReset: (): string => `${authApiBaseUrl}/password/reset`,
};

export const subscriptionApi = {
  subscriptions: (): string => `${apiUrlV2}/subscriptions`,
  subscriptionDevices: (): string => `${apiUrlV2}/subscriptions/devices`,
  paymentMethod: (): string => `${apiUrlV2}/subscriptions/payment-method`,
  paymentSummary: (): string => `${apiUrlV2}/subscriptions/summary`,
  billingHistory: (): string => `${apiUrlV2}/subscriptions/billing-history`,
  trial: (): string => `${apiUrlV2}/trial`,
  convertPlusToEdu: (): string => `${apiUrlV2}/subscriptions/convert-plus-to-edu`,
  voucher: (code: string): string => `${apiUrlV2}/vouchers/${code}`,
  activateVoucher: (code: string): string => `${apiUrlV2}/vouchers/${code}/activate`,
};

export const userApi = {
  user: (): string => `${apiBaseUrl}/user`,
  userSettingsById: (userId: number): string => `${apiBaseUrl}/users/settings/${userId}`,
  userName: (): string => `${apiBaseUrl}/user/name`,
  userSettings: (): string => `${apiBaseUrl}/users/settings`,
  usersBulkSettings: (): string => `${apiBaseUrl}/users/bulksettings`,
  resetPassword: (): string => `${apiUrlV3}/user/password `,
  acceptTerms: (): string => `${apiBaseUrl}/accept-terms`,
  userLogoutLegacy: (): string => `${apiUrlV3}/user/logout`,
};

export const organizationApi = {
  organizationOverview: (): string => `${apiBaseUrl}/organizations/overview`,
  organizations: (): string => `${apiBaseUrl}/organizations`,
  organization: (orgId: number): string => `${apiBaseUrl}/organizations/${orgId}`,
  organizationByCountryCode: (countryCode: string): string =>
    `${apiBaseUrl}/organizations/country-code/${countryCode}`,
  organizationByName: (name: string): string => `${apiBaseUrl}/organizations/name/${name}`,
  invitationByOrganizationId: (orgId: number): string =>
    `${apiBaseUrl}/organizations/${orgId}/invite-with-settings`,
  invitationToOrganization: (orgId: number): string =>
    `${apiBaseUrl}/organizations/${orgId}/invitations`,
  organizationMember: (orgId: number, userId: number): string =>
    `${apiBaseUrl}/organizations/${orgId}/members/${userId}`,
  invitationAccessibleGroups: (): string => `${apiBaseUrl}/invitation/accessibleGroups`,
  organizationSettings: (): string => `${apiBaseUrl}/organizations/settings`,
  organizationAlertNotificationTestEmails: (): string =>
    `${apiBaseUrl}/organizations/alert-notifications/device-offline`,
  calendarConnections: (): string => `${apiBaseUrl}/organizations/calendars/authentications`,
  calendarConnectionById: (authId: number): string =>
    `${apiBaseUrl}/organizations/calendars/authentications/${authId}`,
  billingEmail: (): string => `${apiUrlV3}/organization/billing/email`,
  billingAddress: (): string => `${apiUrlV3}/organization/billing/address`,
  nextInvoice: (): string => `${apiUrlV3}/organization/next-invoice`,
};

export const deviceApi = {
  deviceToken: (): string => `${apiBaseUrl}/devices`,
  devices: (): string => `${apiBaseUrl}/devices`,
  deviceById: (id: number): string => `${apiBaseUrl}/devices/${id}`,
  getDeviceLogsToken: (deviceId: number): string => `${apiUrlV2}/device/${deviceId}/logs`,
  downloadDeviceLogs: (token: string): string => `${apiUrlV2}/device/logs/${token}`,
  exportDeviceData: (): string => `${apiBaseUrl}/devices/download/csv`,
  deviceSettings: (): string => `${apiBaseUrl}/devices/settings`,
  deviceOOBSettings: (): string => `${apiUrlV2}/oob/settings`,
  deviceActivation: (macAddress: string, platform: DevicePlatform): string =>
    `${apiUrlV2}/device/activation?mac=${macAddress}&platform=${platform}`,
  getDeviceSettings: (): string => `${apiBaseUrl}/devices/get-settings`,
  devicePeripherals: (deviceId: number): string => `${apiBaseUrl}/devices/${deviceId}/peripherals`,
  latestFirmwareVersions: (): string => `${apiUrlV2}/latest-firmware-versions`,
  deviceBulkCommand: (command: DeviceCommand): string =>
    `${backendUrl}/devices/commands/${command}/bulk`,
  screenshotThumb: (deviceId: number, type: string, hashOption: string): string =>
    `${backendUrl}/devices/${deviceId}/screenshot/${type}${hashOption}`,
};

export const oauthApi = {
  getAccessToken: (service: OauthServiceEnum, userId: string, authCode?: string): string => {
    const searchParams = new URLSearchParams();
    searchParams.append('userId', userId);
    searchParams.append('redirectUrl', `${document.location.origin}/auth-redirect`);

    if (authCode) {
      searchParams.append('authCode', authCode);
    }

    // Note: if you get error 500 when trying to authorize with an authCode, check your
    // env variables, and make sure you have redirectUrl set correctly set
    return `${apiUrlV2}/oauth/${service}?${searchParams.toString()}`;
  },
};

export const signageApi = {
  templates: (): string => `${apiUrlV2}/signage/overview`,
  template: (): string => `${apiUrlV2}/signage`,
  templateById: (templateId: number): string => `${apiUrlV2}/signage/${templateId}`,
  // Legacy endpoints
  getPlaylists: (deviceId: number): string => `${apiUrlV2}/apps/playlists/${deviceId}`,
  postPlaylists: (): string => `${apiUrlV2}/apps/playlists`,
  convertTemplate: (deviceId: number): string => `${apiUrlV2}/apps/playlists/${deviceId}/convert`,
};

export const invitationApi = {
  acceptInvitation: (): string => `${apiBaseUrl}/accept-invitation`,
  validateInvitation: (): string => `${apiBaseUrl}/validate-invitation`,
};

export const groupsApi = {
  groups: (): string => `${apiBaseUrl}/groups`,
  groupById: (groupId: number): string => `${apiBaseUrl}/groups/${groupId}`,
  groupOrder: (): string => `${apiBaseUrl}/group-order`,
  groupDeploymentKey: (groupId: number): string => `${apiUrlV3}/groups/${groupId}/deployment-key`,
};
